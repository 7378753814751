<template>
    <!-- <the-header/> -->
    <RobotSays/>
</template>

<script>
// import TheHeader from '@/components/TheHeader';
import RobotSays from '@/components/RobotSays';

export default {
  name: 'App',
  components: {
    // TheHeader,
    RobotSays
  }
}
</script>

<style>
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css";
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    margin: 0;
    padding: 0;
}
body {
    font-family: Avenir, Helvetica, Arial, sans-serif;
}
</style>
