<template>
    <h2 style="color: #409EFF"><i class="fa-solid fa-robot"/> says</h2>
    <form @submit.prevent="say">
        <el-input ref="sayInput" v-model.lazy="phrase" placeholder="Say something" clearable>
            <template #append>
                <el-button type="primary" @click="say" style="color: #409EFF"><i class="fa-solid fa-bullhorn"></i></el-button>
            </template>
        </el-input>
    </form>
    <hr size="1"/>
    <div>
        <!--
        <h4>Latest</h4>
        <el-table :data="latestList" style="width: 100%" @cell-click="clickPhrase">
            <el-table-column prop="phrase" label="Phrase"/>
            <el-table-column fixed="right" label="#" width="40" @click.prevent>
                <template #default="scope">
                    <el-popconfirm
                        :key="scope.phrase"
                        confirm-button-text="KILL IT"
                        cancel-button-text="Nah"
                        title="Remove phrase?"
                        @confirm="deletePhrase(scope)"
                        class="confirm"
                        >
                        <template #reference>
                            <i class="fa-solid fa-trash-can"></i>
                        </template>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        -->
        <h4>Popular</h4>
        <el-table :data="phraseList.slice(0,100)" style="width: 100%" @cell-click="clickPhrase" :default-sort="{ prop: 'count', order: 'descending'}" empty-text="Nothing said">
            <el-table-column prop="phrase" sortable>
                <template #header>
                    Phrase&nbsp;&nbsp;
                    <el-popconfirm
                        confirm-button-text="KILL THEM"
                        cancel-button-text="Nah"
                        title="Remove ALL phrases?"
                        @confirm="deleteAllPhrases"
                        class="confirm"
                    >
                        <template #reference>
                            <i class="fa-solid fa-trash-can"></i>
                        </template>
                    </el-popconfirm>
                    &nbsp;&nbsp;
                </template>
            </el-table-column>
            <el-table-column prop="count" fixed="right" label="#" width="60" sortable/>
            <el-table-column fixed="right" label="#" width="40">
                <template #default="scope">
                    <i @click.prevent="deletePhrase(scope)" class="fa-solid fa-trash-can"></i>
                </template>
            </el-table-column>
        </el-table>
    </div>


</template>

<script>
import {/*ElButton,*/ ElInput, ElPopconfirm, ElTable, ElTableColumn} from 'element-plus'
import axios from 'axios';

export default {
    name: "RobotSays",
    components: {
        // ElButton,
        ElInput,
        ElPopconfirm,
        ElTable,
        ElTableColumn
    },
    data() {
        return {
            phrase: '',
            phrases: {},
            latest: [],
            audioCache: {}
        }
    },
    created() {
        this.loadFromLocalStorage();
    },
    mounted() {
        this.sayFocus();
    },
    computed: {
        phraseList() {
            return Object.keys(this.phrases ?? {}).map(p => {
                return {
                    phrase: p,
                    count: this.phrases[p]
                }
            })
        },
        latestList() {
            return (this.latest ?? []).map(p => {
                return {
                    phrase: p
                }
            })
        }
    },
    watch: {
        phrases: {
            handler() {
                this.saveToLocalStorage();
            },
            deep: true
        }
    },
    methods: {
        async say () {
            const phrase = this.phrase.trim();

            if (phrase) {
                this.phrases[phrase] = (this.phrases[phrase] ?? 0) + 1;
                this.deleteLatest(phrase);
                this.latest.unshift(phrase);
                this.latest = this.latest.slice(0, 7);

                const url = await this.getAudioFileUrl(phrase);

                const player = new Audio(url);
                await player.play();
            }

            this.phrase = '';
            this.sayFocus();
        },
        sayFocus() {
            this.$refs.sayInput.focus();
            window.scrollTo(0,0);
        },
        clickPhrase(row) {
            this.phrase = row.phrase;
            this.sayFocus();

        },
        deleteLatest(phrase) {
            if (this.latest.includes(phrase)) {
                delete this.latest[this.latest.indexOf(phrase)]
            }
        },
        deletePhrase(scope) {
            delete this.phrases[scope.row.phrase];
        },
        deleteAllPhrases() {
            this.phrases = {};
        },
        saveToLocalStorage() {
            localStorage.setItem('robotsays.phrases', JSON.stringify(this.phrases));
        },
        loadFromLocalStorage() {

            try {
                const items = localStorage.getItem('robotsays.phrases');
                this.phrases = JSON.parse(items) ?? {};
            } catch (e) {
                console.log('could not load from localstorage', e.message);
            }

        },
        async getAudioFileUrl (phrase) {
            if (this.audioCache[phrase]) {
                return this.audioCache[phrase];
            }
            const url = 'https://6pjsw65hnrvmts6pe6bygwneiy0dkkuz.lambda-url.eu-north-1.on.aws';

            const res = await axios.post(url, {
                text: phrase
            }, {
                headers: {
                    'content-type': 'application/json',
                    'x-api-key': 'this shit is bananas'
                }
            });

            this.audioCache[phrase] = res.data.url;

            return res.data.url;

        }
    }
    // https://6pjsw65hnrvmts6pe6bygwneiy0dkkuz.lambda-url.eu-north-1.on.aws/?lol=yes
}
</script>

<style scoped>
.confirm {
    font-family: Avenir, Helvetica, Arial, sans-serif;
}
</style>
